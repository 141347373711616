@import "../fonts/icomoon/style.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "./themes/index";

html,
body {
  font-family: $font-main;
}

input, button, textarea, div {
  outline: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent;
  transition: background-color 5000s ease-in-out 0s !important;
}

body {
  scroll-behavior: smooth;
}

html,
body,
#root,
.page-wrapper {
  width: 100%;
  height: 100%;
}

.button-group {
  margin: 20px 0 15px;

  button {
    margin-right: 15px;
  }
}

.flex-container {
  display: flex;
}

.form-additional-label {
  margin: 5px 0;
  font-size: 12px;
  color: $color-grey;
}

.form-label {

  @include if-xs {
    margin-bottom: .3rem;
    font-size: 14px;
  }
}

.form-control {

  @include if-xs {
    font-size: 14px;
  }
}

.text-default {
  max-width: 65%;
  text-align: center;
  margin: 0 auto 20px;

  @include if-sm {
    max-width: 100%;
  }
}

.come-back-link {
  position: absolute;
  z-index: 2;

  @include if-xs {
    position: relative;
  }
}
