@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?lakptm');
  src:  url('fonts/icomoon.eot?lakptm#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?lakptm') format('truetype'),
    url('fonts/icomoon.woff?lakptm') format('woff'),
    url('fonts/icomoon.svg?lakptm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-file:before {
  content: "\e904";
}
.icon-arrow-down:before {
  content: "\e905";
}
.icon-arrow-up:before {
  content: "\e906";
}
.icon-eye:before {
  content: "\e903";
}
.icon-404:before {
  content: "\e902";
}
.icon-error:before {
  content: "\e900";
}
.icon-success:before {
  content: "\e901";
}
