@import "./colors";
@import "./fonts";

@mixin button-default {
  display: inline-block;
  max-width: 100%;
  width: auto;
  height: auto;
  min-height: auto;
  padding: 16px 48px;
  border-radius: 3.5em/100%;
  border: none;
  outline: none;
  background-color: $color-green;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #fff;

  @include if-xs {
    padding: 12px 30px;
    font-size: 13px;
  }

  &[disabled] {
    opacity: .5;
    background-color: $color-black;
    cursor: not-allowed;
  }

  &:hover {
    background-color: $color-green-2;
    color: #fff;
  }

  &:focus {
    outline: none;
  }
}

@mixin button-default--danger {
  @include button-default;
  background-color: $color-red;

  &:hover {
    background-color: $color-red-2;
    color: #fff;
  }
}

@mixin button-secondary {
  display: inline-block;
  max-width: 100%;
  width: auto;
  height: auto;
  min-height: auto;
  padding: 2px 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: $color-green;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-weight: 700;

  &[disabled] {
    opacity: .5;
    color: $color-black;
    cursor: not-allowed;
  }

  @include if-xs {
    font-size: 13px;
  }

  &:hover {
    color: $color-green-2;
  }

  &:focus {
    outline: none;
  }
}

@mixin title-primary {
  margin: 0 auto 24px;
  overflow: hidden;
  line-height: 1.4;
  font-family: $font-multi;
  text-align: center;
  font-size: 38px;
  color: $color-green;
  font-weight: 400;

  @include if-sm {
    font-size: 26px;
  }

  span {
    position: relative;
    max-width: 80%;

    @include if-sm {
      max-width: 100%;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 1px;
      width: 100vw;
      top: 50%;
      margin-top: -1px;
      background-color: $color-green;

      @include if-sm {
        display: none;
      }
    }

    &:before {
      right: 100%;
      margin-right: 24px;
    }
    &:after {
      left: 100%;
      margin-left: 24px;
    }
  }
}

@mixin title-secondary {
  margin: 10px 0 20px;
  line-height: 1.4;
  font-family: $font-multi;
  font-size: 32px;
  color: $color-green;

  @include if-sm {
    font-size: 22px;
  }
}


@mixin if-lg {
  @media all and (max-width: 1200px) {
     @content
  }
}

@mixin if-md {
  @media all and (max-width: 992px) {
    @content
  }
}

@mixin if-sm {
  @media all and (max-width: 768px) {
    @content
  }
}

@mixin if-xs {
  @media all and (max-width: 576px) {
    @content
  }
}
